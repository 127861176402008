<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="reflexos"
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Fotomotor direto</label>
          <b-row>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  :value="form.fields.fotomotorDiretoDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('fotomotorDiretoDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  :value="form.fields.fotomotorDiretoEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('fotomotorDiretoEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
        <button
          class="btn blue-underline button-copy center"
          @click=" () => copyEyeValues('fotomotorDiretoDireito','fotomotorDiretoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon />
        </button>
    </b-row>
    <b-row>    
      <b-col>
        <div class="form-group">
          <label>Consensual</label>
          <b-row>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  :value="form.fields.consensualDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('consensualDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  :value="form.fields.consensualEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('consensualEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
        <button
          class="btn blue-underline button-copy center"
          @click="
            () => copyEyeValues('consensualDireito', 'consensualEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon />
        </button>
      </b-row>
    <b-row>  
      <b-col>
        <div class="form-group">
          <label>DPAR</label>
          <b-row>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  :value="form.fields.dparDireito"
                  :readonly="!canEdit"
                  @input="el => updateForm('dparDireito', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  :value="form.fields.dparEsquerdo"
                  :readonly="!canEdit"
                  @input="el => updateForm('dparEsquerdo', el.target.value)"
                  @blur="updateMedicalRecord(form)"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
        <button
          class="btn blue-underline button-copy center"
          @click="() => copyEyeValues('dparDireito', 'dparEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <v-copy-icon />
        </button>
      </b-row>
    <b-row>  
  </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Copy from '@/assets/icons/copy.svg'

export default {
  components: { FormWrapper, EyeFill, 'v-copy-icon' : Copy, },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.reflexos,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/reflexos', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.updateForm(leftEyeFieldName, this.form.fields[rightEyeFieldName])
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>